import React, { useState } from "react";
import Modal from "react-modal";
import  lin from "../../RobotronicsPakistan_Portfolio_2024.pdf"
import { Link } from "react-router-dom";


Modal.setAppElement('#root')

const NavLinks = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const confirmDownload = () => {
    closeModal();
    const url = lin;
    const link = document.createElement("a");
    link.href = url;
    link.download = "RobotronicsPakistan_Portfolio_2024.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <a
        className="px-4 font-extrabold text-gray-500 hover:text-yellow"
        smooth
       href="/"
      >
        Home
      </a>
      <a
        className="px-4 font-extrabold text-gray-500 hover:text-yellow"
        smooth
       href="/#services"
      >
        Services
      </a>
      <a
        className="px-4 font-extrabold text-gray-500 hover:text-yellow"
       href="/#about"
      >
        About
      </a>

      <Link
      className="px-4 font-extrabold text-gray-500 hover:text-yellow"
      to="/contact">
          Contact Us
      </Link>
      {/* <a
        className="px-4 font-extrabold text-gray-500 hover:text-yellow"
       href="/#contact"
      >
        Contact Us
      </a> */}
      <button
        className="text-white bg-yellow hover:bg-gold inline-flex items-center cursor-pointer justify-center w-auto px-6 py-3 shadow-xl rounded-xl"

        onClick={openModal}
      >
        Portfolio
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Download"
        className="modal fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-8 z-50"
        overlayClassName="overlay fixed top-0 left-0 w-full h-full bg-black opacity-90 z-40"
      >
        <h2 className="text-xl font-bold mb-4">Confirm Download</h2>
        <p className="text-gray-700 mb-4">Are you sure you want to download the Portfolio?</p>
        <div className="flex justify-center">
          <button
            onClick={confirmDownload}
            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mr-2"
          >
            Yes
          </button>
          <button onClick={closeModal} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded">
            No
          </button>
        </div>
      </Modal>
      
    </>
  );
};

export default NavLinks;
